import React from "react";

export default function PCMCoursesCredits() {
    return (
        <div className="course-content">
            <h2>Programme in Community Health Management (PCM)</h2>
            <h6>Term wise Courses and Credits</h6>
            <table className="custom-table table-text-left mt-3">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>PDM Courses</th>
                        <th>Credit(s)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Accounting for Development Management 1</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Basics of Community Health</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Principles and Practices of Insurance</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Basics of Development</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Communication for Development 1</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Managerial Economics</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Motivation, Values and Ethics in Development & Change 1</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Participatory Learning Methods 1</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Quantitative Techniques for Management Research 1</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Social Development Research Capacity Building</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>Social Environment Change & Effect on Development</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>Sustainable Livelihoods Development 1</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>Written Analysis and Communication 1</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>Yoga 1</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Fieldwork Segment 1</td>
                        <td>7.0</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <b>Term I Total</b>
                        </td>
                        <td>
                            <b>16.5</b>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Accounting for Development Management 2</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Building People's Organisations for Development 1</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Communication with Communities</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>
                            Interpersonal Behaviour in Personal and Organisation Development
                        </td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Legal Environment for Development 1</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Macroeconomics</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Microfinance for Poverty Reduction</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Participatory Learning Methods 2</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Research Methods for Development</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Social Development</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>Written Analysis and Communication 2</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>Yoga 2</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>Sustainable Livelihoods and Health</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Fieldwork Segment 2</td>
                        <td>7.0</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <b>Term II Total</b>
                        </td>
                        <td>
                            <b>16.0</b>
                        </td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Financial Management</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Human Resource Management</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Managerial Oral Communication</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Marketing of Development Programmes & Concepts 1</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Yoga 3</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Building People's Organisations for Health</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Community Epidemiology</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Counselling Skills</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Health Policyt</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Management Skills</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <b>Development Practice Segment 1</b>
                        </td>
                        <td>
                            <b>10.0</b>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <b>Term III Total</b>
                        </td>
                        <td>
                            <b>16.0</b>
                        </td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Disaster Preparedness, Migration and Management</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Human Resource Management</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Management Information Systems for Development</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Marketing of Development Programmes & Concepts 2</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Quantitative Techniques for Management Research 2</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Communication for Development 2</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Yoga 4</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Demography</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Health Project Evaluation </td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Health Project Planning</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <b>Development Practice Segment 2</b>
                        </td>
                        <td>
                            <b>10.0</b>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <b>Term IV Total</b>
                        </td>
                        <td>
                            <b>16.5</b>
                        </td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Audio Visual Communication for Development</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Leadership for Development</td>
                        <td>1.0</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Motivation, Values and Ethics in Development and Change 2</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Strategic Management</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Corporate Social Responsibility for Development</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Yoga 5</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Society and Health</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>SBCC Strategy</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Gender and Health</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Health Behavioural Pattern and Changes</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>Institutional Pan-India for Reflective Exposure</td>
                        <td>2.0</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>Professional Practice Segment</td>
                        <td>0.5</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <b>Term V Total</b>
                        </td>
                        <td>
                            <b>8.0</b>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <b>TOTAL CREDITS</b>
                        </td>
                        <td>
                            <b>73.0</b>
                        </td>
                    </tr>
                </tbody>

                <thead>
                    <tr>
                        <th>Term</th>
                        <th>Credit(s)</th>
                        <th>PCM Courses</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>I</td>
                        <td>0.5</td>
                        <td>Basics of Community Health</td>
                    </tr>
                    <tr>
                        <td>III</td>
                        <td>1.0</td>
                        <td>Community Epidemiology</td>
                    </tr>
                    <tr>
                        <td>III</td>
                        <td>0.5</td>
                        <td>Counselling Skills</td>
                    </tr>
                    <tr>
                        <td>III</td>
                        <td>0.5</td>
                        <td>Health Policy</td>
                    </tr>
                    <tr>
                        <td>III</td>
                        <td>0.5</td>
                        <td>Management Skills</td>
                    </tr>
                    <tr>
                        <td>IV</td>
                        <td>0.5</td>
                        <td>Demography</td>
                    </tr>
                    <tr>
                        <td>IV</td>
                        <td>0.5</td>
                        <td>Health Project Evaluation </td>
                    </tr>
                    <tr>
                        <td>IV</td>
                        <td>1.0</td>
                        <td>Health Project Planning</td>
                    </tr>
                    <tr>
                        <td>V</td>
                        <td>0.5</td>
                        <td>Society and Health</td>
                    </tr>
                    <tr>
                        <td>V</td>
                        <td>0.5</td>
                        <td>SBCC Strategy</td>
                    </tr>
                    <tr>
                        <td>V</td>
                        <td>0.5</td>
                        <td>Gender and Health</td>
                    </tr>
                    <tr>
                        <td>V</td>
                        <td>0.5</td>
                        <td>Health Behavioural Pattern and Changes</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>7.0</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>


        </div>
    );
}
