import React, { useState } from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/img/logo-with-text.png";

export default function Navbar() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [hoverClass, setHoverClass] = useState("")

  const addHover = () => {
    setHoverClass("")
  }

  const removeHover = () => {
    setHoverClass("no-hover")
  }
  const collapseMenu = () => {
    setMenuCollapse(false);
  };


  return (
    <header>
      <div className="header-info">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center px-md-5">
            <a href="mailto:tda@dhan.org" className="info-mail">
              <i className="far fa-envelope"></i>&nbsp; tda@dhan.org
            </a>
            <ul className="social-medias">
              <li>
                <a
                  href="https://www.facebook.com/thedhanacademy"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square"></i>
                </a>
              </li>
              <li className="ms-3">
                <a
                  href="https://www.instagram.com/the_dhan_academy?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="

                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li className="ms-3">
                <a
                  href="https://www.youtube.com/channel/UCll4SkVeqwnKoi5b9n2X4pA"
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li className="ms-3">
                <a
                  href="https://www.linkedin.com/company/dhanacademy?lipi=urn%3Ali%3Apage%3Ad_flagship3_company_admin%3BuxpfO6OfTjOtcYZoWy82GA%3D%3D"
                  target="_blank"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="header-middle">
        <div className="container-fluid">
          <div className="row px-md-5">
            <div className="col-10 col-sm-12 col-md-5">
              <Link to={"/"}>
                <img className="logo" src={Logo} alt="The DHAN Academy" />
              </Link>
            </div>
            <div className="col-2 d-flex align-items-center d-md-none">
              <button
                className="menu-btn"
                onClick={() => setMenuCollapse(true)}
              >
                <i className="far fa-bars"></i>
              </button>
            </div>
            <div className="col-12 col-sm-12 col-md-7">
              <div className="d-flex justify-content-center flex-wrap justify-content-md-end align-items-center pt-3 pt-md-0 h-100">
                <div className="media-body mb-2 me-md-4 mb-md-0">
                  <p>
                    <i className="far fa-envelope"></i>
                    <span className="d-none d-md-block">Mail Us Today</span>
                  </p>
                  <a href="mailto:tda@dhan.org">tda@dhan.org</a>
                </div>
                <div className="media-body me-md-4 mb-2 mb-md-0 mx-3 mx-md-0">
                  <p>
                    <i className="far fa-phone-alt"></i>
                    <span className="d-none d-md-block">
                      Call us for more details
                    </span>
                  </p>
                  {/* contact only during 9 am to 5pm */}
                  <a
                    href="tel:9677583405"
                    className="media-heading subtitle"
                  >
                    {" "}
                    96775 83405
                  </a>
                </div>
                <div className="media-body mb-2 mb-md-0">
                  <p>
                    <i className="far fa-map-marker-alt"></i>
                    <span className="d-none d-md-block">Location</span>
                  </p>
                  <a className="media-heading subtitle">
                    T. Malaipatti, Thenkarai, Madurai
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-nav">
        <div className="header-nav-wrapper navbar-scrolltofixed">
          <div className="container">
            <nav id="menuzord" className="menuzord orange">
              <ul
                className={`menuzord-menu ${menuCollapse ? "menu-show" : ""} ${hoverClass}`}
                onMouseEnter={addHover}
              >
                <div className="menu-header">
                  <h6>Menu</h6>
                  <button onClick={() => setMenuCollapse(false)}>
                    <i className="far fa-times-circle"></i>
                  </button>
                </div>
                <li onClick={removeHover}>
                  <a>
                    About Us <i className="far fa-angle-down"></i>
                  </a>
                  <ul className="dropdown">
                    <li onClick={collapseMenu}>
                      <Link
                        onClick={() => setMenuCollapse(false)}
                        to={"/about-us"}
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <a>
                        People <i className="fas fa-angle-right"></i>
                      </a>
                      <ul className="dropdown">
                        <li onClick={collapseMenu}>
                          <Link to={"/faculty"}>Faculty</Link>
                        </li>
                        <li onClick={collapseMenu}>
                          <Link to={"/students"}>Students</Link>
                        </li>
                      </ul>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/contact"}>Contact us</Link>
                    </li>
                  </ul>
                </li>
                <li onClick={removeHover}>
                  <a href="#">
                    Academics <i className="far fa-angle-down"></i>
                  </a>
                  <ul className="dropdown">
                    {/* <li>
                      <Link
                        to={"/admission"}
                      >Admission</Link>
                    </li> */}
                    {/* <li>
                      <a href="#">
                        Online Application{" "}
                        <i className="fas fa-angle-right"></i>
                      </a>
                      <ul className="dropdown">
                        <li>
                          <a
                            href="https://forms.gle/y3PZ1o2Z2P5YX9AD8"
                            target="_blank"
                          >
                            Apply for Post Graduate Diploma in Development
                            Management
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://forms.gle/y3PZ1o2Z2P5YX9AD8"
                            target="_blank"
                          >
                            Apply for Post Graduate Diploma in Community Health
                            Management
                          </a>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <a href="#">
                        PG Diploma Courses
                        <i className="fas fa-angle-right"></i>
                      </a>
                      <ul className="dropdown">
                        <li onClick={collapseMenu}>
                          <Link
                            to={"/pg-diploma-courses/development-management"}
                          >
                            Programme in Development Management
                          </Link>
                        </li>
                        <li onClick={collapseMenu}>
                          <Link
                            to={
                              "/pg-diploma-courses/community-health-management"
                            }
                          >
                            Programme in Community Health Management
                          </Link>
                        </li>
                        <li onClick={collapseMenu}>
                          <Link to={"/pg-diploma-courses/insurance-management"}>
                            Programme in Insurance Management
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* <li>
                      <a href="#">
                        Academic Programmes{" "}
                        <i className="fas fa-angle-right"></i>
                      </a>
                      <ul className="dropdown">
                        <li>
                          <a href="pgddm.php">
                            Post Graduate Diploma in Development Management
                          </a>
                        </li>
                        <li>
                          <a href="pgdch.php">
                            Post Graduate Diploma in Community Health Management
                          </a>
                        </li>
                      </ul>
                    </li> */}
                    <li onClick={collapseMenu}>
                      <Link
                        to={"/immersion"}
                      >
                        Immersion Programme
                      </Link>
                      {/* <ul className="dropdown">
                        <li>
                          <a href="dip.php">
                            Development Immersion Programme(DIP)
                          </a>
                        </li>
                        <li>
                          <a href="rdip.php">
                            Rural Development Immersion Programme(RDIP)
                          </a>
                        </li>
                        <li>
                          <a href="rplp.php">
                            Rural Participatory and Learning Programme(RPLP)
                          </a>
                        </li>
                        <li>
                          <a href="wip.php">Water Immersion Programme(WIP)</a>
                        </li>
                      </ul> */}
                    </li>
                    <li onClick={collapseMenu}>
                      <Link
                        to={"/executive-education"}
                      >
                        Executive Education
                        {/* <i className="fas fa-angle-right"></i> */}
                      </Link>
                      {/* <ul className="dropdown">
                        <li>
                          <a href="#">Development Executive Education(DEEP)</a>
                        </li>
                        <li>
                          <a href="#">Development Management Programme(DMP)</a>
                        </li>
                        <li>
                          <a href="#">
                            Short Term Course on Farmer Producer
                            Organization(FPO)
                          </a>
                        </li>
                      </ul> */}
                    </li>
                    <li>
                      <a href="#">
                        Online Courses <i className="fas fa-angle-right"></i>
                      </a>
                      <ul className="dropdown">
                        {/* <li><a href="lbwp.php">Land Based Water Pollution</a></li> */}
                        <li onClick={collapseMenu}>
                          <Link
                            to={"/online-courses/land-based-waterpollution"}
                          >
                            Land Based Water Pollution
                          </Link>
                        </li>
                        {/* <li>
                          <a href="#">Advanced Diploma in Microfinance</a>
                        </li>
                        <li>
                          <a
                            href="http://courses.thedhanacademy.in/"
                            target="_blank"
                          >
                            Online Platform Moodle
                          </a>
                        </li> */}
                      </ul>
                    </li>
                  </ul>
                </li>
                <li onClick={(removeHover, collapseMenu)}>
                  <Link to={"/research"}>Research</Link>
                </li>
                <li onClick={removeHover}>
                  <a href="#">
                    Centres <i className="far fa-angle-down"></i>
                  </a>
                  <ul className="dropdown">
                    <li onClick={collapseMenu}>
                      <Link to={"/centers/acewe"}>
                        Advanced Centre for Enabling Women Enterprises (ACEWE)
                      </Link>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/centers/acese"}>
                        Advanced Centre for Enabling Social entrepreneurs
                        (ACESE)
                      </Link>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/centers/askmi"}>
                        Advanced Centre for Skill and Knowledge on Mutual
                        Insurance (ASKMI),
                      </Link>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/centers/acedrr"}>
                        Advanced Centre for Enabling Disaster Risk Reduction
                        (ACEDRR)
                      </Link>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/centers/wkc"}>
                        Water Knowledge Centre (WKC)
                      </Link>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/centers/acesuham"}>
                        Advanced Centre for Enabling Sustainable Health care
                        advancement (ACESUHAM)
                      </Link>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/centers/mrc"}>Media Resource Centre</Link>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/centers/ccg"}>
                        Advance Centre for Enabling Governance
                      </Link>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/centers/acefin"}>
                        Advanced Centre of Excellence in Financial Management
                        for Development (ACEFIN)
                      </Link>
                    </li>
                  </ul>
                </li>
                <li onClick={removeHover}>
                  <a href="#">
                    Life @ TDA <i className="far fa-angle-down"></i>
                  </a>
                  <ul className="dropdown">
                    <li onClick={collapseMenu}>
                      <Link to={"/morning-reflection"}>
                        Morning Reflection (Prayer)
                      </Link>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/yoga"}>Yoga</Link>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/recreation"}>Recreation &amp; Shramadan</Link>
                    </li>
                    <li >
                      <a href="#">
                        Events <i className="fas fa-angle-right"></i>
                      </a>
                      <ul className="dropdown">
                        <li onClick={collapseMenu}>
                          <a
                            href="https://thedhanacademy.wordpress.com/heritage/"
                            target="_blank"
                          >
                            Heritage
                          </a>
                        </li>
                        <li onClick={collapseMenu}>
                          <a href="https://thedhanacademy.wordpress.com/budget-watch/">Budget Watch</a>
                        </li>
                        <li onClick={collapseMenu}>
                          <a href="https://thedhanacademy.wordpress.com/ethnic-dinner/">Ethnic Dinner</a>
                        </li>
                        <li onClick={collapseMenu}>
                          <a href="https://thedhanacademy.wordpress.com/sports-week/">Sports Week</a>
                        </li>
                        <li onClick={collapseMenu}>
                          <a href="https://thedhanacademy.wordpress.com/thiruvalluavarday">Thiruvalluvar Day</a>
                        </li>
                        <li onClick={collapseMenu}>
                          <a href="https://thedhanacademy.wordpress.com/independence-and-republic-days/">Republic Day, Independence Day</a>
                        </li>
                      </ul>
                    </li>
                    <li onClick={collapseMenu}>
                      <Link to={"/structure"}>Infrastructure</Link>
                    </li>
                    <li>
                      <a href="#">
                        Student Initiatives{" "}
                        <i className="fas fa-angle-right"></i>
                      </a>
                      <ul className="dropdown">
                        <li onClick={collapseMenu}>
                          <a href="https://tdastudents.wordpress.com/spectrum/">Spectrum(Student Magazine)</a>
                        </li>
                        <li onClick={collapseMenu}>
                          <a href="https://tdastudents.wordpress.com/ashwatha-club/">Ashwatha Club</a>
                        </li>
                        <li onClick={collapseMenu}>
                          <a
                            target="_blank"
                            href="https://tdastudents.wordpress.com/"
                          >
                            Student Blog
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li onClick={removeHover}>
                  <a href="#">
                    External Relations <i className="far fa-angle-down"></i>
                  </a>
                  <ul className="dropdown">
                    <li onClick={collapseMenu}>
                      <Link to="/placement">Placement</Link>
                    </li>
                    {/* <li><a href="network.php">Network</a></li> */}
                    <li cl="">
                      <a href="#">
                        Network <i className="fas fa-angle-right"></i>
                      </a>
                      <ul className="dropdown dropdown-left">
                        <li onClick={collapseMenu}>
                          <Link to="/south-asia-network">
                            Agriculture and Water Pollution
                            <br />
                            South Asia Network
                          </Link>
                        </li>
                        {/* <li><a href="pgdch.php">Post Graduate Diploma in Community Health Management</a></li> */}
                      </ul>
                    </li>
                    <li onClick={collapseMenu}>
                      <a href="https://thedhanacademy.wordpress.com/news-press-release">In News & Press Release</a>
                    </li>
                  </ul>
                </li>
                <li onClick={(removeHover, collapseMenu)}>
                  <Link to={"/alumni"}>Alumni</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
