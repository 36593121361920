import { Link } from "react-router-dom";

import PageHeader from "../components/PageHeader";

import PDM19 from "../assets/pdf/brochure2019.pdf";
import PDM20 from "../assets/pdf/Brochure.pdf";
import PDM21 from "../assets/pdf/Brochure21.pdf";

export default function CommunityHealthManagement() {
  return (
    <div>
      <PageHeader
        currentPage={"Placement"}
        travelPages={["External Relations", "Placement"]}
      />

      <div className="container-fluid my-5 px-md-5">
        <div className="row justify-content-center network">
          <div className="col-md-7">
            <h1 className="title-text mb-md-5 mb-4">Placement</h1>
            <p>
              Three years of direct action with poor communities through campus
              placement: Giving Back to the Society – the 2nd phase of
              grassroots experience of PGDDM & PGDCHM Graduates are placed in
              designated NGOs, where they are required to work for a minimum
              period of three years.
            </p>
            <h2 className="subtitle-text">
              The Academy defines a designated development organization as an
              organization which:
            </h2>
            <ul>
              <li>
                Has adequate space for working with poor communities i.e. field
                based programmed projects.
              </li>
              <li>
                Is able to nurture and groom individuals by providing a
                challenging and professional work environment.
              </li>
              <li>
                Is able to provide reasonable remuneration to the graduates at
                par with the best in the sector
              </li>
            </ul>
            <p>
              As the placement of students in the right kind of organisation is
              indispensable to motivate, inspire, and retain them in the
              development sector, the Academy takes utmost care in designating
              organisations. The placement is facilitated considering the mutual
              interest of both the graduates and the identified organisations
              through a process of campus placement. The responsibilities of the
              graduates in the organisationsplaced involve planning and
              implementing development programmes for the poor.
            </p>
            <h2 className="subtitle-text">
              After graduating from PDM & PCM, next three years
            </h2>
            <p>
              The Academy builds the PDM & PCM graduates as Development
              Professionals, who
            </p>
            <ul>
              <li>
                Accept and pursue development career of working with
                disadvantaged communities towards becoming life time development
                worker
              </li>
              <li>
                Work at the grassroots to enable poor for setting their
                development agenda and evolved solutions
              </li>
              <li>
                Be a change agent for equity and justice with actions involving
                honesty and integrity
              </li>
              <li>Accept challenges and strive for excellence</li>
              <li>
                Understand the field issues and research on the changes to
                experiment contextualized interventions with mainstream
                collaboration
              </li>
              <li>
                Build experiential knowledge base on development and poverty
                reduction and strive for its dissemination to different
                development stakeholders
              </li>
              <li>
                After successful completion of two-year PDM & PCM in the
                Academy, they are equipped to take up the career with the
                designated NGOs through campus placement and commence working
                with poor in rural/urban slum/tribal contexts.
              </li>
            </ul>
            <p>
              During three years of the working period requirement, each
              graduated student is expected to work with poor families and
              implement different poverty reduction programmes by building
              communities and promoting people organisations. During this
              three-year working period, they would shape their vision and
              mission and enhance their leadership qualities as well as
              facilitate large scale development processes through policy
              advocacy efforts.
            </p>
            <h2 className="subtitle-text">Previous Placement</h2>
            <p>
              Fifteen batches of PDM & PCM graduates were placed in designated
              development organisations:
            </p>
            <ul>
              <li>
                Aga Khan Rural Support Programme (AKRSP), Bihar, Gujarat and
                Madhya Pradesh
              </li>
              <li>CharutarArogya Mandal, Gujarat</li>
              <li>Child In Need Institute (CINI), West Bengal</li>
              <li>
                DHAN Foundation, Tamil Nadu, Andhra Pradesh, Karnataka, Madhya
                Pradesh, Odisha, Rajasthan, Assam, Jharkhand, Bihar,
                Maharashtra, and Kerala
              </li>
              <li>
                Foundation for Ecological Security (FES), Gujarat, Odisha,
                Karnataka, and Andhra Pradesh
              </li>
              <li>
                National Dairy Development Board (NDDB), Gujarat, Assam,
                Maharashtra, and Uttar Pradesh
              </li>
              <li>PRATHAM, Rajasthan and New Delhi</li>
              <li>Prayatn, Rajasthan</li>
              <li>Sanghamitra, Tamil Nadu</li>
              <li>
                Society for Assistance to Children in Difficult Situation
                (SATHI), Karnataka
              </li>
              <li>Shramik Bharti, Uttar Pradesh</li>
              <li>
                South Indian Federation of Fishermen Societies (SIFFS), Kerala
              </li>
              <li>Srijan, New Delhi</li>
              <li>Tribal Health Initiative (THI), Tamil Nadu</li>
            </ul>
            <p>
              All graduates are involved in direct action with the community in
              various thematic areas such as microfinance, micro insurance,
              information technology for the poor, water, rainfed farming,
              education, health, livelihood promotion, conservation of
              bio-diversity, environment and natural resource management,
              panchayati raj and youth.
            </p>
          </div>
          <div className="col-md-3">
            <div className="sticky-left">
              <h1 className="title-text mb-md-5 mb-4">Brochures</h1>
              <ul className="list-select">
                <li>
                  <a href={PDM20} target="_blank">
                    PDM 20 <i className="fad fa-chevron-double-right"></i>
                  </a>
                </li>
                <li>
                  <a href={PDM21} target="_blank">
                    PDM 21 <i className="fad fa-chevron-double-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
