import { useState, useRef } from "react";

import PageHeader from "../components/PageHeader";
import { Link } from 'react-router-dom';

export default function Donate() {
    const headRef = useRef();
    const [activeTab, setActiveTab] = useState(1);

    const changeTab = (index) => {
        setActiveTab(index);
        headRef.current.scrollIntoView();
    };
    return (
        <div>
            <PageHeader
                currentPage={"DONATE"}
                travelPages={["Donate"]}
            />
            <div className="container pt-md-5 pt-4 pb-5">
                <div className="course-content">
                    <h1 className="text-center mb-5">Enabling, Empowering and Transforming lives of the poor !</h1>

                    <figure className="text-center">
                        <blockquote class="blockquote">
                            <h2 className="mt-5">"Education is the most powerful weapon which you can use to change the world."</h2>
                        </blockquote>
                        <figcaption class="blockquote-footer text-center">
                            Someone famous in <cite title="Source Title">Nelson Mandela</cite>
                        </figcaption>
                    </figure>

                    <p>Dear all, </p>
                    <p>By choosing to donate to TDA, individuals and organizations have a meaningful opportunity to contribute to transformative journey of young graduates to development missionary bringing changes in the lives of down-trodden, vulnerable and poor community.
                    </p>
                    <h6>Academy offers three ways of giving!</h6>
                    <ul>
                        <li>Student fellowship</li>
                        <li>   Infrastructure</li>
                        <li>   General Corpus Donation                        </li>
                    </ul>
                    <p>The Academy has 12 A, 80 G and GST registration and fulfilling legal compliances without violation. Whoever wishes to donate, kindly share an email to ‘tda@dhan.org’. Our team will reach out to you with details for the donation</p>

                </div>
                <div ref={headRef}></div>
                <div className="row mt-5">
                    <div className="col-md-3">
                        <ul className="list-select">
                            <li
                                className={activeTab == 1 ? "active" : ""}
                                onClick={() => changeTab(1)}
                            >
                                Student Fellowship  <i className="fad fa-chevron-double-right"></i>
                            </li>
                            <li
                                className={activeTab == 2 ? "active" : ""}
                                onClick={() => changeTab(2)}
                            >
                                Infrastructure <i className="fad fa-chevron-double-right"></i>
                            </li>
                            <li
                                className={activeTab == 3 ? "active" : ""}
                                onClick={() => changeTab(3)}
                            >
                                General Corpus Donation <i className="fad fa-chevron-double-right"></i>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-9">
                        {activeTab == 1 && (
                            <div className="course-content">
                                <h2 className="text-center">Student Fellowship</h2>
                                <p className="text-center" style={{ fontStyle: "italic" }}>Sponsor a young graduate to serve the poor communities!  </p>
                                <h6 className="text-center">Fellowship Per student: Rs. 6,00,000</h6>
                                <h5 className="mt-4">The Principle Behind Full Fellowships for All Students</h5>
                                <p>The Academy facilitates 100 percent fellowship support for all because it believes that students should not get burdened by borrowing an education loan for undertaking the course, which might hinder or block their commitment of serving society. The Academy believes that the graduates of PDM are morally responsible to the society to give back in the form of service whatever they have availed from it for their development. The Academy does not differentiate the students by class, caste and religion for availing the fellowship assistance as everyone is expected to work for the deserving and marginalized communities. Moreover, they are sensitized that they are morally responsible and accountable to serve the poor.
                                </p>
                                <p>The Academy expects that each graduate would be able to serve at least 3,000 poor families within three to five years of their joining in a development organisation and they would reach more in due course. They would commit to the designated organisation and the communities with whom they serve to ensure desired changes in the lives of the poor; hence every student matters for the Academy. In this context, The DHAN Academy seeks your financial assistance/educational fellowship to young graduates aspiring towards development management education
                                </p>
                                <ul>
                                    <li><p>Written test – Assessment of basic English, Mathematics,</p></li>
                                    <li><p>Group Discussion: A short caselet will be provided. Candidates have to read and discuss.</p></li>
                                    <li><p>Personal Interview -  to assess their Motivation and Attitude to work with community
                                        (this may happen either through online / centres chosen by academy)</p></li>
                                </ul>
                                <h6 className="text-center">Fellowship Breakup per student
                                </h6>
                                <div className="d-grid mb-3">
                                    <table className="custom-table table-text-left mt-3">
                                        <thead>
                                            <tr>
                                                <th>Particulars</th>
                                                <th>Amount – per Student </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Tuition</td>
                                                <td>1,70,000</td>
                                            </tr>
                                            <tr>
                                                <td>Boarding</td>
                                                <td>2,00,000</td>
                                            </tr>
                                            <tr>
                                                <td>Lodging</td>
                                                <td>1,30,000</td>
                                            </tr>
                                            <tr>
                                                <td>Travel</td>
                                                <td>60,000</td>
                                            </tr>
                                            <tr>
                                                <td>Books and Stationery</td>
                                                <td>30,000</td>
                                            </tr>
                                            <tr>
                                                <td>Internet and Library</td>
                                                <td>5,000</td>
                                            </tr>
                                            <tr>
                                                <td>Extra-Curricular Activities, Medicals and Miscellaneous</td>
                                                <td>5000</td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td>6,00,000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6>Term wise Fees Breakup (Class room Segment + Field Segment)</h6>
                                <div className="d-grid mb-3">
                                    <table className="custom-table table-text-left mt-4">
                                        <thead>
                                            <tr>
                                                <th>Terms</th>
                                                <th>Tution</th>
                                                <th>Boarding</th>
                                                <th>Lodging</th>
                                                <th>Travel</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Term 1</td>
                                                <td>35,000</td>
                                                <td>40,000</td>
                                                <td>26,000</td>
                                                <td>10,000</td>
                                            </tr>
                                            <tr>
                                                <td>Term 2</td>
                                                <td>35,000</td>
                                                <td>40,000</td>
                                                <td>26,000</td>
                                                <td>10,000</td>
                                            </tr>
                                            <tr>
                                                <td>Term 3</td>
                                                <td>35,000</td>
                                                <td>45,000</td>
                                                <td>28,000</td>
                                                <td>14,000</td>
                                            </tr>
                                            <tr>
                                                <td>Term 4</td>
                                                <td>35,000</td>
                                                <td>45,000</td>
                                                <td>28,000</td>
                                                <td>14,000</td>
                                            </tr>
                                            <tr>
                                                <td>Term 5</td>
                                                <td>30,000</td>
                                                <td>30,000</td>
                                                <td>22,000</td>
                                                <td>12,000</td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td>1,70,000</td>
                                                <td>2,00,000</td>
                                                <td>1,30,000</td>
                                                <td>60,000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p>Term wise also donation can be received</p>
                                <p>Or General donation in the name of scholarship can be given</p>

                            </div>
                        )}
                        {activeTab == 2 && (
                            <div className="course-content">
                                <h2 className="text-center">Infrastructure</h2>
                                <p>You can donate towards unique – one-of-a-kind architecture of academy</p>
                                <ul>
                                    <li><p>Classrooms – 80 Lakhs </p> </li>
                                    <li><p>Knowledge centres - 30 Lakhs (Single Floor) and 75 Lakhs (Two Floors)
                                    </p></li>
                                    <li><p>Hostel one block with 20 rooms (Two Floors) – 3 Crore</p></li>
                                    <li><p>Hostel one block with 10 rooms (Two Floors) - 1 Crore </p></li>
                                    <li><p>Multi-purpose Auditorium Hall – 2 Crore</p></li>
                                    <li><p>Fencing (Wall) – 1 Crore</p></li>
                                    <li><p>Fencing (Barbed Wire) – 15 Lakhs</p></li>
                                    <li><p>Solar Panel and Power – 20 Lakhs</p></li>
                                </ul>
                            </div>
                        )}
                        {activeTab == 3 && (
                            <div className="course-content">
                                <h2 className="text-center">General Corpus Donation</h2>
                                <ul>
                                    <li><p>Platinum donor (Rs 26,00,000 – 1 Crore)</p> </li>
                                    <li><p>Golden donor (Rs 11,00,000 - 25,00,000)</p></li>
                                    <li><p>Silver donor (Rs 1,00,000 – 10,00,000) </p></li>
                                    <li><p>Bronze donor (Rs 10,000 – 1,00,000)</p></li>
                                </ul>
                            </div>
                        )}
                        <div className="course-content">
                            <p>Interested persons can send a mail to ‘tda@dhan.org’ for bank account details</p>
                        </div>
                    </div>

                </div>
            </div >
        </div >
    );
}
